/* eslint-disable no-underscore-dangle */
import { useCallback, useEffect, useState } from 'react';
import type { FC } from 'react';
import {
  Container,
  Typography,
  Box,
  Grid
} from '@mui/material';
import useIsMountedRef from 'src/hooks/useIsMountedRef';
import useSession from 'src/hooks/useSession';
import { AutocompleteResponse } from 'src/api/dto/search/AutocompleteResponse';
import axios from 'axios';
import { KumaoTheme } from 'src/theme';
import { serverConfig } from 'src/config';
import { OrganizationSearchResponse } from 'src/api/dto/search/OrganizationSearchResponse';
import useSettings from 'src/hooks/useSettings';
import OrgCard from 'src/components/search/OrgCard';
import SearchResetButton from 'src/components/search/SearchResetButton';
import SentimentDissatisfiedIcon from '@mui/icons-material/SentimentDissatisfied';
// import AdditionalSearchFilter from 'src/components/search/AdditionalSearchFilter';

export interface SimpleProps {
  onSearch?: (organizationsFilter: OrganizationSearchResponse) => void;
}

const OrganizationSearchResults: FC<SimpleProps> = (props) => {
  const { onSearch } = props;
  const isMountedRef = useIsMountedRef();
  const { settings } = useSettings();
  const { session } = useSession();
  const [organizations, setOrganizations] = useState<OrganizationSearchResponse | null>(
    null
  );

  // const [AddFilterChecked, setAddFilterChecked] = React.useState(false);

  const getOrganizations = useCallback(async (localSearchTerm: string) => {
    const query = {
      query: {
        multi_match: {
          query: localSearchTerm,
          fields: ['company_name^3', 'last_name^3', 'branchesname^2', 'html_content^2', 'zip_code^4', 'city^4', 'county^3', 'street_name^1', 'services^2', 'events^2'],
          fuzziness: 'AUTO',
          fuzzy_transpositions: true
        }
      }
    };
    try {
      const instance = axios.create();
      delete instance.defaults.headers.common.Authorization;
      instance.defaults.headers['Content-Type'] = 'application/json;charset=utf-8';
      console.log(`orgSearchORG: ${localSearchTerm}`);
      const response = await instance.post<OrganizationSearchResponse>(
        serverConfig.searchUrl, JSON.stringify(query), {
          auth: {
            username: 'readall',
            password: 'readonly'
          } }
      );
      if (isMountedRef.current) {
        console.group('SEARCH');
        console.log(response.data);
        console.groupEnd();
        response.data.hits.hits.map((searchObj, i) => (
          response.data.hits.hits[i]._source.branches = JSON.parse(searchObj._source.branchesjson)
        ));
        setOrganizations(response.data);
        if (onSearch) {
          onSearch(response.data);
        }
      }
    } catch (err) {
      console.error(err);
    }
  }, [isMountedRef]);

  const getOrganizationsByBranche = useCallback(async (localSearchTerm: string) => {
    const query = {
      query: {
        multi_match: {
          query: localSearchTerm,
          fields: ['branchesname^7', 'company_name^2', 'last_name^2', 'description^2', 'zip_code^3', 'city^3', 'county^3', 'street_name^1'],
          fuzziness: 'AUTO',
          fuzzy_transpositions: true
        }
      }
    };
    try {
      const instance = axios.create();
      delete instance.defaults.headers.common.Authorization;
      instance.defaults.headers['Content-Type'] = 'application/json;charset=utf-8';
      console.log(`orgSearchORGBYBRANCH: ${localSearchTerm}`);
      const response = await instance.post<OrganizationSearchResponse>(
        serverConfig.searchUrl, JSON.stringify(query), {
          auth: {
            username: 'readall',
            password: 'readonly'
          } }
      );
      if (isMountedRef.current) {
        console.group('SEARCH branch');
        console.log(response.data);
        console.groupEnd();
        response.data.hits.hits.map((searchObj, i) => (
          response.data.hits.hits[i]._source.branches = JSON.parse(searchObj._source.branchesjson)
        ));
        setOrganizations(response.data);
        if (onSearch) {
          onSearch(response.data);
        }
      }
    } catch (err) {
      console.error(err);
    }
  }, [isMountedRef]);

  /**
   * Convert the Autocomplete response into a string list
   * @param data Response from the autocomplete service call
   * @returns String[]: Unique list for autocomplete  suggestions
   */
  const getListFromAutocompleteResponse = (data: AutocompleteResponse) => {
    const items = [];
    data.suggest.autocomplete.map((entries) => (
      entries.options.map((item) => (
        items.push(item.text)
      ))
    ));
    return [...new Set(items)]; // to remove duplicates
  };

  const loadAutocompleteAndSearch = (searchString: string) => {
    if (!searchString || searchString.length === 0) {
      return;
    }

    (async () => {
      const instance = axios.create();
      delete instance.defaults.headers.common.Authorization;
      instance.defaults.headers['Content-Type'] = 'application/json;charset=utf-8';
      const query = {
        suggest: {
          autocomplete: {
            prefix: searchString,
            completion: {
              field: 'content_txt'
            }
          }
        }
      };
      const response = await instance.post<AutocompleteResponse>(
        serverConfig.autoCompleteSearchUrl, JSON.stringify(query), {
          auth: {
            username: 'readall',
            password: 'readonly'
          } }
      );

      if (response) {
        const mylist = getListFromAutocompleteResponse(response.data);
        getOrganizations(`${searchString} ${mylist.join(' ')}`);
      }
    })();
  };

  useEffect(() => {
    console.log(`orgsearchMODE: ${session.selectedSearchMode}`);
    if (session && session.selectedSearchMode) {
      if (session.selectedSearchMode === 'Alle' && session.searchTerm) {
        console.log(`orgsearchTERM: ${session.searchTerm}`);
        loadAutocompleteAndSearch(session.searchTerm);
      } else {
        getOrganizationsByBranche(`${session.selectedSearchMode} ${session.searchTerm}`);
      }
    } else {
      setOrganizations(null);
    }
  }, [session]);

  if (!organizations || (session.searchTerm.length === 0 && session.selectedSearchMode === 'Alle')) {
    return null;
  }

  return (
    <Container
      maxWidth={settings.compact ? 'xl' : false}
      sx={{
        paddingTop: 1,
        paddingBottom: 5,
        borderRadius: 5,
        marginBottom: 3
      }}
    >
      <Grid
        justifyContent="space-between"
        container
        spacing={2}
      >
        <Grid
          item
        >
          <Typography
            variant="h3"
            sx={{
              pl: 3,
              color: (theme: KumaoTheme) => theme.palette.primary[600],
              pt: 0.5
            }}
          >
            Suchergebnisse
          </Typography>
        </Grid>
        <Grid
          item
        >
          <SearchResetButton />
        </Grid>
      </Grid>

      {
        // <AdditionalSearchFilter
        //   organizations={organizations}
        // />
        }

      <Box
        component="div"
        sx={{ display: 'flex',
          flexWrap: 'wrap',
          pt: 2, }}
      >
        {(organizations.hits.total.value === 0 && (
          <Box
            sx={{ pl: 4 }}
          >
            <Grid
              container
              spacing={2}
            >
              <Grid item>
                <SentimentDissatisfiedIcon sx={{ fontSize: 30, color: (theme: KumaoTheme) => theme.palette.secondary.main }} />
              </Grid>
              <Grid item>
                <Typography
                  sx={{ fontWeight: 'bold', color: (theme: KumaoTheme) => theme.palette.secondary.main }}
                >
                  Es tut uns Leid...
                </Typography>
                <Typography
                  sx={{ color: (theme: KumaoTheme) => theme.palette.secondary.main }}
                >
                  Leider kein Treffer, mit diesem Suchbegriff!
                </Typography>
                <Typography
                  sx={{ color: (theme: KumaoTheme) => theme.palette.secondary.main, pt: 2 }}
                >
                  Wir sind derzeit noch in der Testphase. Du kannst gerne die Karte oder Newcomer-Bereich nutzen, um zu schauen welche Dienstleister bereits auf Kumao sind.
                </Typography>
              </Grid>
            </Grid>
          </Box>
        ))}
        {organizations.hits.hits.map((org) => (
          <OrgCard
            key={org._id}
            orgCardSearch={org._source}
          />
        ))}
      </Box>
    </Container>
  );
};

export default OrganizationSearchResults;
